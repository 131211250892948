<template>
	<main id="company-scroll-container">
		<CompanyPageWrapper
			v-if="company && company._id"
			:company-id="company._id"
			@activate-jobalarm="$emit('activate-jobalarm')"
		/>
		<div
			v-if="swipeFcts && $page && $page.isOpen"
			class="swipe-actions flex fixed inset-x-0 bottom-0 justify-center pb-1"
		>
			<Transition name="bounce" appear>
				<button class="bg-color-purple hover:bg-color-purple-hover" @click="callDislikeAction">
					<HokIcon class="mx-auto" name="icon:swipe-left" color="white" :size="5" />
				</button>
			</Transition>
			<Transition name="bounce" appear>
				<button class="bg-color-blue hover:bg-color-blue-hover" @click="callLikeAction">
					<HokIcon
						v-if="company && company.swipeAction === 'application'"
						class="mx-auto"
						name="icon:swipe-right"
						color="white"
					/>
					<HokIcon v-else class="mx-auto" name="icon:jobalarm" color="white" :size="8" />
				</button>
			</Transition>
		</div>
	</main>
</template>

<script lang="ts">
import type { IAPICompany, APITypeObjectId, APIObjectType } from '@hokify/common';
import type { PropType } from 'vue';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { defineNuxtComponent, definePageMeta, useRoute, useRelationsStore } from '#imports';
import CompanyPageWrapper from '@/components/user/CompanyPageWrapper.vue';
import { useCompanyStore } from '@/stores/company';

export default defineNuxtComponent({
	name: 'CompanyDetailOverview',
	setup() {
		definePageMeta({
			layout: 'user',
			path: '/pwa/c/:companyId?'
		});
	},
	components: {
		CompanyPageWrapper
	},
	emits: ['activate-jobalarm', 'swipe-dislike'],
	async asyncData({ $pinia }) {
		const { params } = useRoute();
		const companyStore = useCompanyStore($pinia);
		const relationsStore = useRelationsStore($pinia);
		let companyId;
		let audienceId;

		if (params?.companyId) {
			companyId = params.companyId;
		} else if (relationsStore.numberOrId) {
			companyId = relationsStore.numberOrId.idOrNr;
		}

		if (params?.audienceId) {
			audienceId = params.audienceId;
		} else if (relationsStore.numberOrId) {
			audienceId = relationsStore.numberOrId.audienceId;
		}

		try {
			const res = await companyStore.getCompany(companyId, audienceId);
			return {
				company: res.company
			};
		} catch (err: any) {
			if (this.$nuxt.$isHokFetchResponseError(err)) {
				throw createError({
					statusCode: err.response.status,
					message:
						err.response.data && err.response.data.code
							? `${err.response.data.code}: ${err.response.data.msg || err.response.data.message}`
							: `Es ist ein Fehler aufgetreten${
									err.response.data ? ` (${JSON.stringify(err.response.data)})` : ''
								}`
				});
			} else {
				throw createError(err);
			}
		}
	},
	data() {
		const company = undefined as undefined | IAPICompany;
		const showApplyButton = false;
		return {
			company,
			swipeFcts: false,
			openedViaLike: false,
			showApplyButton,
			EventBus
		};
	},
	created() {
		if (this.company?.allowUnsolicitedApplications) {
			this.showApplyButton = this.company?.allowUnsolicitedApplications;
		}
	},
	mounted() {
		/* this.$trackUserEvent?.('detail_view_enlarge', {
			company: { _id: this.company?._id, name: this.company?.name, audienceId: this.audienceId }
		}); */
	},
	methods: {
		callLikeAction() {
			if (this.company?.swipeAction === 'discover') {
				if (this.openedViaLike) {
					this.$page.goBack();
					this.EventBus.$emit('activate-jobalarm');
				} else {
					this.EventBus.$emit('activate-jobalarm');
					this.EventBus.$emit('swipe-dislike');
				}
			} else {
				this.EventBus.$emit('swipe-like');
			}
		},
		callDislikeAction() {
			if (this.openedViaLike) {
				this.$page.goBack();
			} else {
				this.$emit('swipe-dislike');
			}
		}
	},
	props: {
		audienceId: {
			type: String as PropType<APITypeObjectId<APIObjectType.CompanyAudience>>,
			required: false,
			default: ''
		}
	}
});
</script>

<style scoped lang="scss">
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.bounce-enter-active {
	animation: bounce-in 0.5s;
}
@keyframes bounce-in {
	0% {
		transform: scale(0);
	}
	50% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1);
	}
}
.swipe-actions {
	button {
		margin: 5px;
		width: 64px;
		height: 64px;
		text-align: center;
		outline: none;
		border-radius: 50%;
		transition: color 600ms ease;
	}
}
</style>
